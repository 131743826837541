@layer base {
  body {
    margin: 0;
    background: #f5f7fb;
    overflow-y: scroll;
  }

  html,
  body,
  #root {
    height: 100%;
  }
}
